import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class WeAreHiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-we-are-hiring custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "38794b50-89f9-442a-98e9-dcf6edc96974",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1313}
                  businessType={"merchant"}
                  locationId={1378}
                  subheader={"Hiring Form"}
                  showLabels={true}
                  emailSubject={"Hiring Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone_number",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Phone number",
                    },
                    {
                      type: "input",
                      label: "address",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Address",
                    },
                    {
                      type: "input",
                      label: "birthdate",
                      required: true,
                      inputType: "text",
                      placeholder: "dd/mm/yyyy",
                      displayLabel: "Birthdate",
                    },
                    {
                      type: "input",
                      label: "previous_work_experience_1",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Previous Work Experience 1",
                    },
                    {
                      type: "input",
                      label: "previous_work_experience_2",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Previous Work Experience 2",
                    },
                    {
                      type: "input",
                      label: "phone_number_of_current_or_last_employer",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Phone number of current or last employer",
                    },
                    {
                      type: "input",
                      label: "reason_for_leaving_previous_employer",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Reason for leaving previous employer",
                    },
                    {
                      type: "input",
                      label: "date_first_available",
                      required: true,
                      inputType: "text",
                      placeholder: "dd/mm/yyyy",
                      displayLabel: "Date first available",
                    },
                    {
                      type: "input",
                      label: "hours_available",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Hours Available",
                    },
                    {
                      type: "input",
                      label: "days_available",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Days Available",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22846 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
